import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Home} from './Home';
import {Letter} from './Letter';
import {Bunny} from './Bunny';
function App() {

  return (
    <Router> 
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Letter" component={Letter} />
        <Route exact path="/Bunny" component={Bunny} />
    </Switch> 
  </Router>
  );
}

export default App;
